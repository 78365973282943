import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap";
import loadable from "@loadable/component";
import Layout from "../components/layout";

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const PeopleDetailIntro = loadable(() => import("../components/PeopleDetailIntro/PeopleDetailIntro"));
const PeopleDetailDesc = loadable(() => import("../components/PeopleDetailDesc/PeopleDetailDesc"));
const PeopleDetailSidebar = loadable(() => import("../components/PeopleDetailSidebar/PeopleDetailSidebar"));

const PeopleDetail = (props) => {

    // Sticky scroll
    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 80)
        })
    },[])
    // Sticky scroll

    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule />

            <div className="people-detail-wrapper">
                <Container>
                    <Row>
                        <Col xl={8} className="order-xl-1 order-2">
                            <PeopleDetailIntro />

                            <PeopleDetailDesc />
                        </Col>
                        <Col xl={1} className="order-xl-2"></Col>
                        <Col xl={3} className="order-xl-3 order-1">
                            <div className={`people-sidebar position-sticky ${scroll ? "scrolled" : ""}`}>
                                <PeopleDetailSidebar />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query {
    allWpPost(filter: {categories: {nodes: {elemMatch: {slug: {eq: "news"}}}}, status: {eq: "publish"}}, sort: {order: DESC, fields: date}) {
      nodes {
        uri
        title
        status
        slug
        categories {
          nodes {
            name
          }
        }
        content
        featuredImage {
            node {
              id
              sourceUrl
            }
        }
      }
    }
  }
`


export default PeopleDetail